#conteudo {
    
    width: 100%;
    height: 100%;
    display: flex;
    gap: 10px;
}

#conteudo>#configuracoes {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    max-width: 400px;
    min-width: 300px;
    /* max-width: calc(100% / 3);
    min-width: 300px; */
    padding: 15px 10px;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    box-shadow: 0px 0px 12px -3px rgb(0 0 0 / 47%);
    gap: 10px;
}

#conteudo>#configuracoes>h2 {
    text-align: center;
    margin: 0;
    width: 100%;

    font-weight: 600;
    font-size: 1.5rem;
}

#conteudo>#configuracoes>form {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 15px;
}

#conteudo>#configuracoes>form>div {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

#conteudo>#configuracoes>form>div>label {
    font-weight: 600;
    font-size: 1.1rem;
}

#disclaimer {
    display: flex;
    align-items: center;
    border: 2px solid var(--warning);
    padding: 10px;
    border-radius: 20px;
    font-weight: 600;
}

#conteudo>#configuracoes>#cardPaciente {
    display: flex;
    flex-direction: column;
    gap: 5px;
    box-shadow: 0px 0px 10px -5px rgb(0 0 0 / 44%);
    border-radius: 20px;
    padding: 20px;
    align-items: center;
    margin: 0px 5px;
}

#conteudo>#configuracoes>#cardPaciente>#fotoPaciente {
    >img {
        height: 80px;
    }
}

#conteudo>#configuracoes>#cardPaciente>#dadosPaciente {
    display: flex;
    flex-direction: column;
    gap: 5px;
    font-size: 1.1rem;
    align-items: center;

    >h2 {
        font-size: 1.3rem;
        font-weight: 600;
        color: var(--primary);
        margin: 0;
    }

    >h3 {
        font-size: 1.1rem;
        font-weight: 600;
        color: var(--primary);
        margin: 0;

    }
}

#conteudo>#principal {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;    
    padding: 10px;

}

#conteudo>#principal>.headerPrincipal {
    color: #1e1e1e;
    width: 100%;
    text-align: center;
    font-size: 1.2rem;
}

#conteudo>#principal>.containerEtapas {
    display: flex;
    flex-direction: row;
    flex: 1;
    width: 100%;
    border-radius: 20px;
    box-shadow: 0px 0px 10px -5px rgb(0 0 0 / 44%);
    overflow: hidden;
    position: relative;
    /* margin: 10px 0px; */
}

#conteudo>#principal>.containerEtapas>.etapa {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    /* padding: 10px; */
    height: 100%;
    text-align: center;
    padding: 15px;
    overflow: hidden auto;
}


.etapa.explicando {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    /* background-color: var(--info); */
    overflow-y: auto;

    /* width */
    &::-webkit-scrollbar {
        width: 12px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
        /* box-shadow: inset 0 0 5px grey; */
        border-radius: 20px;

    }

    /* Handle */
    &::-webkit-scrollbar-thumb {

        border-radius: 20px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {}

    >p {
        margin-top: 20px;
        font-size: 1.2rem;
        font-weight: 600;

    }

    >ol {
        margin-top: 20px;
        font-size: 1.2rem;
        font-weight: 600;
        text-align: left;
        width: 100%;

        >li {

            margin-top: 5px;
        }
    }

    >.explicandoComImagens {
        display: flex;
        flex: 1;
        justify-content: center;
        align-items: center;
        /* border: 1px solid #5ccddf; */
        box-shadow: 0px 0px 6px 1px #d9e2e9;
        padding: 30px;
        margin: 20px;
        border-radius: 20px;
        gap: 10px;
        max-width: 600px;

        >div {
            flex: 1;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-start;


            >img {
                width: 50%;
            }

            >span {
                font-size: 1rem;
                font-weight: 600;
                margin-top: 10px;
                letter-spacing: .5px;
            }
        }
    }
}

.etapa.selecionando {
    background-color: whitesmoke;
    align-items: normal !important;
    transform: translateX(100%);
    gap: 30px;

    >label {
        margin-left: 30px;
    }

    >label>div {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        transition: all .4s ease-in-out;
        position: relative;

        &:hover {
            cursor: pointer;
            transform: scale(1.02);
        }

        >.select {
            position: absolute;
            top: 9px;
            left: -30px;
            width: 25px;
            height: 25px;
            border-radius: 50%;
            background-color: #dbdbdb;
            z-index: 1;
            opacity: 1;
            display: flex;
            justify-content: center;
            align-items: center;

            >i {
                font-size: 18px;
                opacity: 0;
                transition-duration: .6s;
            }
        }

        >span {
            font-size: 1.4rem;
            font-weight: 600;
            /* width: 100%; */
            text-align: left;
            display: inline;
            padding: 5px 10px;
            border-radius: 10px;
            margin-bottom: 10px;
        }

        >p {
            margin: 0;
            font-size: 1.2rem;
            font-weight: 600;
            width: 100%;
            text-align: left;
            padding: 10px;
            border-radius: 10px;


        }

        >input:checked~p {
            cursor: pointer;
            background-color: #dbdbdb;
            color: cornflowerblue;
        }

        >input:checked~span {
            cursor: pointer;
            background-color: #dbdbdb;
            color: cornflowerblue;

        }

        >input:checked~.select {
            opacity: 1;
            color: cornflowerblue;
        }

        >input:checked~.select>i {
            opacity: 1;
        }

        >input {
            display: none;
        }
    }
}

.etapa.gravando {
    padding: 0px !important;
    color: #fefefe;
    transform: translateX(200%);
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    flex: 1;
    background-color: #343a40;

    >.gravandoContainerSoundBars {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: flex-end;
        flex: 1;
        background-color: #343a40;
        width: 100%;

        >canvas {
            width: 100%;
            height: 80%;
        }
    }

    >.gravandoContainerElementos {
        position: absolute;
        top: 0px;
        width: 100%;
        height: 100%;
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: #343a40a3;
        padding-top: 20px;
        gap: 10px;
        backdrop-filter: blur(12px);
        justify-content: center;

        >.micContainer {
            width: 100px;
            height: 100px;
            background-color: red;
            box-shadow: 0px 0px 25px 3px rgb(255, 0, 0);
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            transition: all .4s ease-in-out;

            &:hover {
                transform: scale(.9);
                box-shadow: 0px 0px 15px 1px rgb(200, 1, 1);
                background-color: rgb(200, 1, 1);

                >i {
                    transform: scale(1.4);
                }
            }

            >i {
                transition: all .4s ease-in-out;

                font-size: 30px;
            }
        }



    }
}

.tempoContainer {
    display: flex;
    flex-direction: column;
    position: absolute;
    left: 10px;
    bottom: 20px;
    text-align: start;

    >span {
        font-size: 16px;
        font-weight: 300;
        color: #8d9fb2;
    }
}

.etapa.revisando {
    background-color: #fff;
    transform: translateX(300%);
    display: flex;
    flex-direction: column;
    flex: 1;

    >p {
        font-size: 16px;
        text-align: center;
        display: block;
        margin-bottom: 10px;
        font-weight: 600;
        color: var(--primary);
    }

    >div {
        flex: 1;
        width: 100%;
        border-top-right-radius: 20px;
        border-top-left-radius: 20px;
        overflow: hidden;
        padding: 15px;
    }

    #textoResumoAudioIA {
        border-radius: 6px;
        width: 100%;
        height: 100%;
        resize: none;
    }
}

.textoGravando {
    font-weight: 600;
    /* text-shadow: 0px 0px 10px rgb(255, 0, 0); */
    letter-spacing: 2px;
    color: red;
}

#conteudo>#principal>.acoesEtapas {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 50px;
    align-items: flex-end;
    position: relative;
    overflow-x: hidden;
}

#conteudo>#principal>.acoesEtapas>div {
    position: absolute;
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    gap: 10px;
    text-align: center;    
}

#conteudo>#principal>.acoesEtapas>.acaExplicando {}

#conteudo>#principal>.acoesEtapas>.acoesSelecionando {

    transform: translateX(100%);
}

#conteudo>#principal>.acoesEtapas>.acoesGravando {
    transform: translateX(200%);

}

#conteudo>#principal>.acoesEtapas>.acoesRevisando {
    transform: translateX(300%);

}

.blob {
    animation: pulse-red 2s infinite;
}


.etapaAtual {
    animation: zoomIn 1s;
}


.containerSelectMic {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    gap: 10px
}

.containerSelectMic>div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
}

.backToLeft {
    transform: translateX(-100%);
}

.containerSelectMic>div>button {
    margin: auto;
}

.containerSelectMic>div>span {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 10px;
}

.textGravandoTeste {
    margin: auto;
    color: red;
}

.loading-spinner {
    background: #333;
    background: rgba(0, 0, 0, 0.8);
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;
    display: none;
}


.loading-spinner.active {
    display: block;
}

#hourglass {
    /* centraliza no meio da tela */
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -32px 0 0 -32px;
}

.loading-spinner {
    background: #333;
    background: rgba(0, 0, 0, 0.8);
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;
    display: none;
}

.loader {
    border: 16px solid #f3f3f3;
    /* Light grey */
    border-top: 16px solid #3498db;
    /* Blue */
    border-radius: 50%;
    width: 120px;
    height: 120px;
    animation: spin 2s linear infinite;
}

.loading-bol {
    background: #333;
    background: rgba(0, 0, 0, 0.919);
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;
    display: none;
    /* display: flex; */
    flex-direction: column;
    justify-content: center;
    align-items: center;
    backdrop-filter: blur(12px);

    >p {
        font-size: 20px;
        color: #fff;
        font-weight: 400;
    }

    >h2 {
        margin-bottom: 20px;
        color: #fff;
    }
}

.loading-bol.active {
    display: flex;
}

.loaderBol {
    width: 150px;
    height: 150px;
    background-color: var(--primary);
    border-radius: 50%;
    position: relative;
    box-shadow: 0 0 30px 4px rgba(0, 0, 0, 0.5) inset,
        0 5px 12px rgba(0, 0, 0, 0.15);
    overflow: hidden;
    margin-bottom: 20px;
}

.loaderBol:before,
.loaderBol:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 40%;
    top: -40%;
    background-color: #bfdbfaad;
    animation: wave 5s cubic-bezier(0, 1.74, 1, 0) infinite;
}

.loaderBol:before {
    border-radius: 30%;
    background: rgba(255, 255, 255, 0.4);
    animation: wave 3s linear infinite;
}

#ephox_textoResumoAudioIA {
    box-shadow: 0px 0px 6px -1px rgb(0 0 0 / 44%);
    border-radius: 20px;
    overflow: hidden;

    >div {
        border: none;
    }
}

.olInstrucoes>li{
    padding-right: 36px;
}

@keyframes wave {
    0% {
        transform: rotate(0);
    }

    100% {
        transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}


@keyframes pulse-red {
    0% {
        transform: scale(0.90);
        box-shadow: 0px 0px 25px 3px rgb(255, 0, 0);
    }

    70% {
        transform: scale(1);
        box-shadow: 0px 0px 25px 30px rgba(255, 82, 82, 0);
    }

    100% {
        transform: scale(0.90);
        box-shadow: 0px 0px 25px 3px rgba(255, 82, 82, 0);
    }
}

@keyframes zoomIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }

}