*{
    box-sizing: border-box;    
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.pulse-red {
    animation: pulse-red 2s infinite;
}

@keyframes pulse-red {
    0% {
        transform: scale(0.90);
        box-shadow: 0px 0px 25px 3px rgb(255, 0, 0);
    }

    70% {
        transform: scale(1);
        box-shadow: 0px 0px 25px 30px rgba(255, 82, 82, 0);
    }

    100% {
        transform: scale(0.90);
        box-shadow: 0px 0px 25px 3px rgba(255, 82, 82, 0);
    }
}

.pulse-color-red {
    animation: pulse-color-red  2s infinite;
}

@keyframes pulse-color-red  {
    0% {
        box-shadow: 0px 0px 25px 3px rgb(255, 0, 0);
    }

    70% {
        box-shadow: 0px 0px 25px 30px rgba(255, 82, 82, 0);
    }

    100% {
        box-shadow: 0px 0px 25px 3px rgba(255, 82, 82, 0);
    }
}

::-webkit-scrollbar {
    width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #fff;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #1976d220;
    border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #1976d250;
}